import React from 'react';
import './PreLoader.css';

const PreLoader = (props) => {

    return (
        <div>
            { !props.isBackgroundImageLoaded && (
                <div className="spinner-wrapper">
                    <div className="spinner">
                        <div className="bounce1"></div>
                        <div className="bounce2"></div>
                        <div className="bounce3"></div>
                    </div>
                </div>
            )}
        </div>
    )
};

export default PreLoader;