import React, { useEffect, useState } from 'react';
import './Nav.css';
import * as constants from '../../constants/appconstants';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';

const Nav2 = (props) => {

    let domainName = window.location.hostname;
    if (domainName === 'localhost') {
        domainName = constants.testDomainForContent;
    }

    const lizsDomain = (domainName === constants.lizsDomain);
    const foreName = lizsDomain ? ' Liz' : 'Aidan';

    const [scroll, setScroll] = useState(false);
    useEffect(() => {
        window.addEventListener("scroll", () => {
            setScroll(window.scrollY > 20);
        });
    }, []);
    let navBarClasses = 'navbar-dark navbar-custom fixed-top';
    if (scroll) {
        navBarClasses = navBarClasses + ' top-nav-collapse';
    }

    return (

        <Navbar className={navBarClasses} expand="lg" collapseOnSelect={true} >
            <Navbar.Brand className="logo-text page-scroll" href="#header"><span className="d-none d-lg-inline">{foreName}</span> Whiteley</Navbar.Brand>

            <Navbar.Toggle className="navbar-toggler" aria-controls="basic-navbar-nav" />

            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="navbar-nav ml-auto">

                    <Nav.Link href="#header">Home <span className="sr-only">(current)</span></Nav.Link>

                    {lizsDomain && (
                        <NavDropdown title="Picture Of The Day" id="basic-nav-dropdown1">
                            <Nav.Link href="#dog-of-the-day-intro">Dog of the Day</Nav.Link>
                            <Nav.Link href="#plant-of-the-day-intro">Plant of the Day</Nav.Link>
                        </NavDropdown>
                    )}

                    <NavDropdown title="The GPS Blog" id="basic-nav-dropdown1">
                        <NavDropdown.Item href="#gpsblog-pics-intro"><span className="item-text">Photos</span></NavDropdown.Item>
                        <NavDropdown.Item href="#gpsblog-cycling-stats"><span className="item-text">Cycling stats</span></NavDropdown.Item>
                        <NavDropdown.Item href="#gpsblog"><span className="item-text">GPS Tracks</span></NavDropdown.Item>
                        <NavDropdown.Item href="#gps-nearest"><span className="item-text">Nearest</span></NavDropdown.Item>
                    </NavDropdown>

                    {!lizsDomain && (
                        <Nav.Link href="#cloudy-books">Books</Nav.Link>
                    )}

                    {!lizsDomain && (
                        <NavDropdown title="Music" id="basic-nav-dropdown2">
                            <NavDropdown.Item href="#music-lastfm"><span className="item-text">Listened</span></NavDropdown.Item>
                            <NavDropdown.Item href="#music-soundcloud"><span className="item-text">Played - old</span></NavDropdown.Item>
                            <NavDropdown.Item href="#music-newer"><span className="item-text">Played - new</span></NavDropdown.Item>
                        </NavDropdown>
                    )}

                    {!lizsDomain && (
                        <Nav.Link href="#software-dev">Software</Nav.Link>
                    )}

                    <Nav.Link href="#contact">Contact</Nav.Link>

                </Nav>

                {!lizsDomain && (
                    <span id="header-social" className="nav-item social-icons">
                        <span className="fa-stack">
                            <a href="https://www.facebook.com/aidan.whiteley.98">
                                <span className="hexagon"></span>
                                <i className="fab fa-facebook-f fa-stack-1x"></i>
                            </a>
                        </span>
                    </span>
                )}

            </Navbar.Collapse>
        </Navbar>
    )

};

export default Nav2;