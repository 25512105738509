import React, { useState } from 'react';
import { Helmet } from "react-helmet";

const DocumentHead = (props) => {
    const [theContent, setContent] = useState(null);

    if (props.cmscontent) {
        props.cmscontent.then(response => {
            if (!response.data.length > 0) {
                console.error('Error getting HTML document header content: ' + JSON.stringify(response));
            } else {
                const content = response.data[0];
                if (content.document_header_id[0]) {
                    setContent(content.document_header_id[0]);
                }
            }
        }).catch(err => {
            console.error('There was an error getting HTML document header content: ' + err);
        });
    }

    return (
        <div>
            {theContent && (
                <Helmet>
                    <title>{theContent.title}</title>
                    <link rel="icon" href={process.env.REACT_APP_CMS_PROTOCOL_DOMAIN + '/assets/' + theContent.favicon} />
                    <link rel="canonical" href={theContent.linkCanonical} />
                    <meta name="description" content={theContent.metaDescription} />
                    <meta name="author" content={theContent.metaAuthor} />
                    <meta property="og:site_name" content={theContent.metaOgSitename} />
                    <meta property="og:description" content={theContent.metaDescription} />
                    <meta property="og:title" content={theContent.title} />
                    <meta property="og:type" content="website" />
                    <meta property="og:locale" content="en_GB" />
                    <meta property="og:image" content="" />
                    <meta property="og:url" content={theContent.metaOgUrl} />
                </Helmet>
            )}
        </div>
    )

};

export default DocumentHead;