import React from 'react';
import './Loading.css';

const Loading = (props) => {

    if (props.timedOut) {
        return <div className="LoaderError"><b>Whoops</b> - it took too long loading the component! <button className="btn btn-danger lazyLoadbtn" onClick={props.retry}>Retry</button></div>;
    } else if (props.error) {
        return <div className="LoaderError"><b>Whoops</b> - there was an error loading the component! <button className="btn btn-danger lazyLoadbtn" onClick={props.retry}>Retry</button></div>;
    } else if (props.pastDelay) {
        return <div className="Loader">Loading...</div>;
    } else {
        return null;
    }
}

export default Loading;