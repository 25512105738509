import React, { Fragment, useState } from 'react';
import './Header.css';
import ReactTextRotator from 'react-text-rotator';
import Nav from '../Nav/Nav';

const Header = (props) => {

    const cmsProtocolDomain = process.env.REACT_APP_CMS_PROTOCOL_DOMAIN

    const [theContent, setContent] = useState(null);
    const [cmsErrMsg, setCmsErrMsg] = useState(null);
    const [backgroundImage, setBackgroundImage] = useState('');

    const backgroundImageStyle = {
        backgroundColor: 'linear-gradient(rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.2))',
        backgroundImage: 'url(' + backgroundImage + ')',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        height: '100vh'
    };

    if (props.cmscontent) {
        props.cmscontent.then(response => {
            if (!response.data.length > 0) {
                setCmsErrMsg('There was an unexpected response while getting the header content from the content management system!');
                console.error('Error getting CMS header content: ' + JSON.stringify(response));
            } else {
                const content = response.data[0];
                setContent(content);

                const width = window.innerWidth;
                let key = 'largelandscape';
                if (width <= 200) {
                    key = 'thumbnaillandscape';
                } else if (width <= 500) {
                    key = 'smalllandscape';
                } else if (width <= 1000) {
                    key = 'mediumlandscape';
                }

                setBackgroundImage(cmsProtocolDomain + '/assets/' + content.imagelandscape + '?key=' + key);
            }
        }).catch(err => {
            setCmsErrMsg('There was an unexpected error getting content for the page header from the content management system!');
            console.error('There was an error getting CMS content: ' + err);
        });
    }

    const getIntroText = () => {
        if (theContent && theContent.introtext) {
            return theContent.introtext;
        } else {
            return 'There was a failure getting content from the content management system';
        }
    }

    const geButtonText = () => {
        if (theContent && theContent.buttontext) {
            return theContent.buttontext;
        } else {
            return 'Click me!';
        }
    }

    const getRotateTextValues = () => {

        let rotateText = [];
        if (theContent.sitelink && theContent.sitelink.length > 0) {
            rotateText = theContent.sitelink.map(t => {
                return { "text": t.text, "link": t.link, "className": "headerLinks" }
            });
        }
        return rotateText;
    }

    return (
        <div>
            {cmsErrMsg && (
                <div className="cmsErrMsg">
                    {cmsErrMsg}
                </div>
            )}

            {!theContent && !cmsErrMsg && (
                <div>
                    Getting content...
                </div>
            )}

            {theContent && (
                <Fragment>
                    <Nav></Nav>

                    <img data-testid="offscreen-image" src={backgroundImage} className="offscreen" onLoad={() => {
                        props.handlebackgroundImageStateChange(true);
                    }}
                        alt="Placeholder to know when background piccie has been downloaded" />

                    <header id="header" className="header" style={backgroundImageStyle}>

                        <div className="header-content">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="text-container">
                                            <h1>
                                                <ReactTextRotator
                                                    content={getRotateTextValues()}
                                                    time={4000}
                                                    startDelay={1000}
                                                />
                                            </h1>

                                            {cmsErrMsg && (
                                                <p className="p-heading p-large">{cmsErrMsg}</p>
                                            )}

                                            {!cmsErrMsg && (
                                                <p className="p-heading p-large">{getIntroText()}</p>
                                            )}
                                            <a className="btn-solid-lg page-scroll" href="#contentStart">{geButtonText()}</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </header>
                </Fragment>

            )}
        </div>
    )
};

export default Header;