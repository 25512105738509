import LoadableVisibility from "react-loadable-visibility/react-loadable";
import Loading from "../Loading/Loading";

const MusicSoundCloudLoadable = LoadableVisibility({
    loader: () => import("./MusicSoundCloudContainer"),
    loading: Loading,
    delay: 200,
    timeout: 3000
});

export default MusicSoundCloudLoadable;