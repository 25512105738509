import React, { useState, useEffect, Fragment } from 'react';
import './App.css';
import PreLoader from './components/PreLoader/PreLoader';
import Header from './components/Header/Header';
import DocumentHead from './components/DocumentHead/DocumentHead';
import MapsCarouselLoadable from './components/MapsCarousel/MapsCarouselLoadable';
import PictureGalleryLoadable from './components/PictureGallery/PictureGalleryLoadable';
import CyclingStatsLoadable from './components/CyclingStats/CyclingStatsLoadable';
import GoogleMapsNearestLoadable from './components/GoogleMapsNearest/GoogleMapsNearestLoadable';
import BooksCoverflowLoadable from './components/BooksCoverflow/BooksCoverflowLoadable';
import MusicLastFMLoadable from './components/MusicLastFM/MusicLastFMLoadable';
import MusicSoundCloudLoadable from './components/MusicSoundCloud/MusicSoundCloudLoadable';
import MusicNewerLoadable from './components/MusicNewer/MusicNewerLoadable';
import SoftwareDevLoadable from './components/SoftwareDev/SoftwareDevLoadable';
import ContactFormLoadable from './components/ContactForm/ContactFormLoadable';
import FooterLoadable from './components/Footer/FooterLoadable';
import * as constants from './constants/appconstants';
import ScrollToTop from 'react-scroll-up';

function App() {

  const domains = [
    {domain: 'aidanwhiteley.com', cmsDomain: 'cms.aidanwhiteley.com', gpsblogDomain: 'thegpsblog.com', googleMapsApiKey: 'AIzaSyAan-dJn2FAWAs3bpJRFPwmFqZCi9lhD9w', booksDomain: 'cloudybookclub.com'},
    {domain: 'aidanwhiteley.local', cmsDomain: 'cms.aidanwhiteley.local', gpsblogDomain: 'thegpsblog.local', googleMapsApiKey: 'dummyVal', booksDomain: 'cloudybookclub.local'},
    {domain: 'localhost', cmsDomain: 'localhost:3000', gpsblogDomain: 'localhost:3000', googleMapsApiKey: 'dummyVal', booksDomain: 'localhost:3000'},
  ];

  const domainName = window.location.hostname;
  const protocol = window.location.protocol;
  const domainDetails = domains.find(s => s.domain === domainName);

  const cmsProtocolDomain = protocol + '//' + domainDetails.cmsDomain;
  const cmsApiPrefix = process.env.REACT_APP_CMS_API_PREFIX;
  const googleApiCheck = protocol + '//' + domainDetails.gpsblogDomain + '/isGoogleApiLimitExceeded';

  const id = constants.aidanId;

  const lizsDomain = false;

  // Stores (JSON) content retreived from the headless CMS.
  const [headerContent, setHeaderContent] = useState(null);
  const [introContent, setIntroContent] = useState(null);
  const [footerContent, setFooterContent] = useState(null);
  const [audioFiles, setAudioFiles] = useState(null);
  const [softwareProjects, setSoftwareProjects] = useState(null);
  const [backgroundImageLoaded, setBackgroundImageLoaded] = useState(false);
  // and whether we should show components that access Google APIs
  const [useGoogleApis, setUseGoogleApis] = useState(true)

  // Kick off the calls to get some JSON files from the headless CMS
  useEffect(() => {

    fetch(cmsProtocolDomain + cmsApiPrefix + '/header?filter[website][_eq]=' + id + '&fields=*,document_header_id.*,sitelink.*')
      .then(res => setHeaderContent(res.json()));

    fetch(cmsProtocolDomain + cmsApiPrefix + '/componentintro?filter[website][_eq]=' + id + '&fields=*.*')
      .then(res => {
        if (res.ok) {
          setIntroContent(res.json());
        } else {
          // For 4xx and 5xx errors
          console.error('Error retrieiving content from the CMS: ' + res.status + ' ' + res.statusText)
          ;
        }
      }, 
      // Following is only for network errors
      () => '');

    fetch(cmsProtocolDomain + cmsApiPrefix + '/audio_files')
      .then(res => setAudioFiles(res.json()));

    fetch(cmsProtocolDomain + cmsApiPrefix + '/softwareproject')
      .then(res => setSoftwareProjects(res.json()));

    fetch(cmsProtocolDomain + cmsApiPrefix + '/footer?filter[website][_eq]=' + id + '&fields=*,link_group.*,link_group.sitelink.*')
      .then(res => setFooterContent(res.json()));

    // A call to check that Google APIs (Maps etc) havent been called so often that we'll end up getting charged 
    // for going above the free limits.
    fetch(googleApiCheck, { method: 'GET' })
      .then(res => {
        if (res.status === 429) {
          setUseGoogleApis(false);
        }
      })

  }, [domainName, id, cmsProtocolDomain, cmsApiPrefix, googleApiCheck]);

  // This strange, somewhat hacky, bit of code is there to support the use case where a user uses the 
  // browser to page "Back" to this page from another site. If the user was scrolled part way
  // down this page, then the InsersectionObserver code doesn't "see" this and the components
  // "above" where the user has scrolled to in the page aren't re-loaded. The user sees blank
  // screen until they page up to _above_ the "missing" component and then the InstersectionObserver 
  // code "kicks in" and the component is loaded.
  // Removing the CSS "min-height" value on the CSS classes that "reserve space" for the components
  // seems to avoid / work round the problem (at the cost of a lot of components all rendering and
  // making their API calls when the user pages back.)
  // See the App.css file for the CSS classes being targetted.
  // The dependency on "domainName" is a hack to make sure that the code only fires once per page load.
  useEffect(() => {
    if ((document.documentElement.scrollTop || document.body.scroll) > 0) {
      document.querySelectorAll('[class$="Placeholder"]').forEach((el) => { el.style.minHeight = "0px" });
    }
  }, [domainName]);


  // This same code is used to run two separate website - aidanwhiteley.com and lizwhiteley.com. 
  // The conditionals below are because the components displayed are not all the same on both websites.
  // At some point I'll change the implementation such that which components are rendered on each website
  // will be driven by content from the CMS. 
  // At that point, I'll have written a WordPress killer for sure... 
  return (
    <div className="App">

      <Fragment>

        <DocumentHead cmscontent={headerContent} />

        <PreLoader isBackgroundImageLoaded={backgroundImageLoaded} />

        <Header cmscontent={headerContent} handlebackgroundImageStateChange={setBackgroundImageLoaded} />

        <div data-testid="contentStart" id="contentStart"></div>

        <div id="gpsblog-pics-intro" className="basic-1a">
          <div className="pictureGalleryPlaceholder">
            <PictureGalleryLoadable cmscontent={introContent} />
          </div>
        </div>

        <div id="gpsblog-cycling-stats" className="cyclingStatsPlaceholder">
          <CyclingStatsLoadable cmscontent={introContent} domaindetails={domainDetails} />
        </div>

        <div id="gpsblog" className="basic-1">
          <div className="mapsCarouselPlaceholder">
            <MapsCarouselLoadable cmscontent={introContent} domaindetails={domainDetails} />
          </div>
        </div>

        {useGoogleApis && (
          <div id="gps-nearest" className="gpsMapsNearestPlaceholder">
            <GoogleMapsNearestLoadable cmscontent={introContent} domaindetails={domainDetails} />
          </div>
        )}

        {!lizsDomain && (
          <Fragment>
            <div id="cloudy-books" className="booksCoverflowPlaceholder">
              <BooksCoverflowLoadable cmscontent={introContent} domaindetails={domainDetails} />
            </div>

            <div id="musicstart"></div>

            <div id="music-lastfm" className="musicLastFMPlaceholder">
              <MusicLastFMLoadable cmscontent={introContent} />
            </div>

            <div id="music-soundcloud" className="musicSoundcloudPlaceholder">
              <MusicSoundCloudLoadable cmscontent={introContent} />
            </div>

            <div id="music-newer" className="musicNewerPlaceholder">
              <MusicNewerLoadable cmscontent={introContent} songs={audioFiles} />
            </div>

            <div id="software-dev" className="softwareDevPlaceholder">
              <SoftwareDevLoadable cmscontent={introContent} projects={softwareProjects} />
            </div>
          </Fragment>)
        }

        <div id="contact" className="contactFormPlaceholder">
          <ContactFormLoadable cmscontent={introContent} />
        </div>

        <div className="footerPlaceholder">
          <FooterLoadable cmscontent={footerContent} />
        </div>

        <ScrollToTop showUnder={48}>
          <span className="d-none d-md-block"><img width="48" height="48" src="../images/up_arrow_round.png" alt="Back to top" /></span>
        </ScrollToTop>

      </Fragment>

    </div>
  );

}

export default App;
